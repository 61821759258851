import React, { PropsWithChildren } from 'react';
import Footer from 'shared/component/navigation/footer';
import TopHeader from 'shared/component/navigation/topHeader';

const Layout: React.FC<PropsWithChildren> = (props) => {
	return (
		<div id='wrapper'>
			<div id='page-wrapper' className='gray-bg'>
				<div className='page-container'>
					<TopHeader />
					{props.children}
					<Footer/>
				</div>
			</div>
		</div>
	);
};

export default Layout;
