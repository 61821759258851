import Homepage from "feature/homePage";
import { Navigate, Route, Routes } from "react-router-dom";
import Privacy from "./feature/privacy";
import Terms from "./feature/terms";
import "./assets/styles/index.scss";

function App() {
	return (
		<Routes>
			<Route path='/' element={<Homepage />} />
			<Route path='/share/post/:postId' element={<Homepage />} />
			<Route path='/terms-of-use' element={<Terms />} />
			<Route path='/privacy-policy' element={<Privacy />} />
			<Route path='*' element={<Navigate replace to='/' />} />
		</Routes>
	);
}

export default App;
