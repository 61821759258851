import queryString from 'query-string';
import { QueryParameters } from '../interface';

const firstLevelBreadcrumbs = [{ name: 'Home', link: '/' }];

const API_CONFIG = {
  baseUrl: `${process.env.REACT_APP_BASE_URL}`,
  path: {
    termsAndUse: 'cms/terms-of-use',
    privacyPolicy: 'cms/privacy-policy',
    postData: 'post/post-data',
  },
};

const getUrl = (url: string, params: QueryParameters = {}): string => {
  if (!url.includes('https')) {
    let urlString = `${API_CONFIG.baseUrl}/${url}`;
    if (params && Object.keys(params).length > 0) {
      urlString += `?${queryString.stringify(params)}`;
    }
    return urlString;
  }
  return url;
};

const pageOptions: number[] = [20, 50, 100];

const PER_PAGE = 20;

export { API_CONFIG, getUrl, firstLevelBreadcrumbs, pageOptions, PER_PAGE };
