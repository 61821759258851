import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <div className='footer container'>
        <p>
          ©2023 ExclamationPoint | All Rights Reserved |{' '}
          <Link to={'/privacy-policy'}>Privacy Policy</Link> |{' '}
          <Link to={'/terms-of-use'}>Terms & conditions</Link>{' '}
          {/* | <Link to={"#"}>Contact Us</Link> */}
        </p>
      </div>
    </>
  );
};

export default Footer;
