import { AppLogo } from "../icon/icon";

const TopHeader = () => {
	return (
		<>
			<div className='topheader-wrapper d-flex  '>
				<div className='header-corner d-flex full-width  '>
				<AppLogo/>
				</div>
				<div className='icons d-flex'>
				</div>
			</div>
		</>
	);
};

export default TopHeader;
