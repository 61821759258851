import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Layout from 'shared/hoc/layout/component/layout';
import BannerImg from '../../assets/images/banner-img.png';
import ButtonImg1 from '../../assets/images/btn-1.png';
import ButtonImg2 from '../../assets/images/btn-2.png';
import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/constants';
interface IParams {
  postId: string;
}

interface IPostData {
  photo: string;
  description: string;
}

const Homepage: React.FC = () => {
  const params = useParams();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [postData, setPostData] = useState<IPostData | undefined>();

  const fetchPostData = useCallback((params: IParams) => {
    HttpService.get(API_CONFIG.path.postData, params)
      .then((res) => {
        setPostData(res);
      })
      .catch((err) => {
        console.log('err:', err);
      });
  }, []);

  useEffect(() => {
    const { postId } = params;
    if (postId) {
      fetchPostData({ postId });
    }
  }, [params, fetchPostData]);

  return (
    <Layout>
      <div className='homepage-wrapper container'>
        <div className='info'>
          <h1>Exclamation Point !!!</h1>
          <h3>Daily creativity challenges for a more imaginative world.</h3>
          <h4>
            <span>Download</span> the free app below
          </h4>
          <div className='btn-wrap d-flex'>
            <Link
              className='btn'
              to={
                'https://apps.apple.com/us/app/exclamation-point/id6453023737'
              }
              target='_blank'
            >
              <img src={ButtonImg1} alt='App store app download' />
            </Link>
            <Link
              className='btn'
              to={
                'https://play.google.com/store/apps/details?id=com.exclamationpoint.android'
              }
              target='_blank'
            >
              <img src={ButtonImg2} alt='Google play download' />
            </Link>
          </div>
        </div>
        <div className='banner-image'>
          <figure>
            <img src={BannerImg} alt='banner img' />
          </figure>
        </div>
      </div>
    </Layout>
  );
};

export default Homepage;
