import { useCallback, useEffect, useState } from "react";
import Loader from "shared/component/loader";
import { API_CONFIG } from "shared/constants/constants";
import HttpService from "shared/services/http.service";

export default function Privacy() {
  const [loading, setLoading] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState("");

  const fetchTermsAndUses = useCallback(() => {
    setLoading(true);
    HttpService.get(API_CONFIG.path.privacyPolicy)
      .then((res) => {
        setLoading(false);
        setPrivacyPolicy(res.page);
      })
      .catch((err) => {
        console.log("err:", err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchTermsAndUses();
  }, [fetchTermsAndUses]);

  return (
    <div>
      {loading ? (
        <Loader className="center full-width full-height" />
      ) : (
        <div
          className="html-content-wrapper"
          dangerouslySetInnerHTML={{ __html: privacyPolicy }}
        ></div>
      )}
    </div>
  );
}
